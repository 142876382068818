<template>
  <!--
    Used to display details about one contact.
  -->
  <div class="border-bottom contactDetail">
    <div class="btn-group float-right">
      <a
        v-if="contact.phone"
        v-tooltip="contact.phone"
        :href="linkForTelephoneApp"
        class="btn btn-sm btn-primary contactDetailFirstButton"
        @click="sendHistoryComment(contact, 'Outgoing Phone Call')"
      >{{ $t('call') }}</a>
      <button
        v-if="!authenticationHasRole('scope_customer') && contact.email && !isCallDuty"
        v-tooltip="$t('freshdesk.createTicket')"
        class="btn btn-primary btn-sm"
        @click="show()"
      >
        <span>FD</span>
      </button>
      <router-link
        v-if="notifyButtonName"
        :to="`/notify/${ installationId }/${ contact.id }`"
        class="btn btn-sm btn-primary"
      >
        {{ notifyButtonName }}
      </router-link>
    </div>
    <div class="btn-group float-right mr-3">
      <JiraNotificationStatus
        :jira-notification-media="contact.notificationMedia"
      />
    </div>
    <div class="font-weight-bold">
      <span
        v-if="contact.firstname"
        class="contactDetailFirstname"
      >{{ contact.firstname + " " }}</span>
      <span />
      
      <span
        v-if="contact.surname"
        class="contactDetailSurname"
      >{{ contact.surname }}</span>
    </div>
    <small v-if="contact.role">{{ contact.role }}
    </small>
    <div class="clearfix" />

    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <FreshdeskTicketAdd
        :contact="contact"
        :installation-id="installationId"
        @close="close"
      />
    </Sidebar>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
export default {
  name: "ContactDetail",
  components: {
    JiraNotificationStatus: () => import('@/components/Jira/JiraNotificationStatus.vue'),
    Sidebar : () => import('@/components/Base/Sidebar.vue'),
    FreshdeskTicketAdd : () => import('@/components/Freshdesk/FreshdeskTicketAdd.vue'),
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    contact: {
      type: Object,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    installationName: {
      type: String,
      required: true
    },
    isCallDuty: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showSidebar: false 
    }
  },
  computed: {
    validSmsContact: function () {
      if (this.contact == null) {
        return false;
      }
      if (this.contact.id == null) {
        return false;
      }
      if (this.contact.phone == null) {
        return false;
      }
      if (this.contact.language == null) {
        return false;
      }
      if (!Array.isArray(this.contact.languageCode) || (this.contact.languageCode && this.contact.languageCode.length === 0)) {
        return false;
      }
      return true;
    },
    notifyButtonName: function () {
      if(this.validSmsContact && this.contact.email) {
        return "SMS/Email";
      }
      else if(this.validSmsContact) {
        return "SMS"
      }
      else if(this.contact.email && Array.isArray(this.contact.languageCode) && !(this.contact.languageCode && this.contact.languageCode.length === 0)) {
        return "Email"
      }
      return null;
    },
    linkForTelephoneApp: function () {
      return `tel:${ this.contact.phone }`;
    },
    pathToSmsComp: function () {
      return `/notify/${ this.installationId }/${ this.contact.id }`;
    },
    linkForEmail: function () {
      return `mailto:${ this.contact.email }?subject=${ this.installationId }%20${ this.installationName }&cc=b2b-support@skiline.cc`;
    }
  },
  methods: {
    refreshHistory () {
      this.$eventBus.$emit('refreshHistory');
    },
    sendHistoryComment (contact, type) {
      let auditPhoneCallDto = {
        InstallationId: this.installationId,
        ContactPersonId: contact.id,
        Type: type
      };
      this.axios.post(`/Audit/AddPhoneCallComment`, auditPhoneCallDto)
        .then(() => {
          this.refreshHistory();
        })
    },
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
  }
}
</script>

<style scoped>
.contactDetail {
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.contactDetail:last-of-type {
  padding-bottom: 5px;
  margin-bottom: 0;
}
small {
  line-height: 1rem;
  height: 1rem;
  display: block;
}
</style>
